import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../redux/common/api";
import { URL } from "../../redux/common/url";

const ReCAPTCHAComponent = ({ onRecaptchaChange }: any) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Handle reCAPTCHA change (i.e., when user completes the challenge)
  const handleRecaptchaChange = async (value: any) => {
    try {
      const res: any = await api.post(URL.verifyCaptcha, { recaptcha: value });
      if (res?.data?.success) {
        onRecaptchaChange(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        marginTop: "70px",
      }}
    >
      <ReCAPTCHA
        sitekey="6Le4EAYrAAAAAC7OdrbzKr6cKho_Xc2_wFu8Vf6k" // Use your reCAPTCHA site key here
        action="submit"
        onChange={handleRecaptchaChange}
      />
    </div>
  );
};

export default ReCAPTCHAComponent;
