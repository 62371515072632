import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { officeAction } from "../../redux/common/action";
import ReCAPTCHAComponent from "../../Component/Service/ReCAPTCHA";
function WelComeForm() {
  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(true);
  const [Office, setOffice] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setOffice({ ...Office, [name]: value });
  };

  const submitQuery = (e: any) => {
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA!");
      return;
    }

    var inputs = $("#" + e).find("input");
    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (inputs.length > 0) {
      $.each(inputs, function (k, v) {
        if (!v.value) {
          $(this).after(
            '<div class="error-class">This field is required.</div>'
          );
          isError = true;
        }
      });
    }
    if (inputs[1].value == "") {
      $("#mob").after('<div class="error-class">This field is required.</div>');
      isError = true;
    }
    if (inputs[1].value) {
      if (inputs[1].value.length != 10) {
        $("#mob").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (inputs[1].value) {
      if (phoneno.test(inputs[1].value) === false) {
        $("#mob").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (inputs[2].value) {
      if (regex.test(inputs[2].value) === false) {
        $("#email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (isError) {
      return false;
    }
    dispatch(officeAction.getQueryAll(Office, history));
    setShowPopup(false);
  };

  useEffect(() => {
    // Simulate loading time

    setTimeout(() => {
      setLoading(false);
    }, 8000); // Replace 2000 with the actual loading time in milliseconds
  }, []);

  if (!showPopup || loading) {
    return null;
  }
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div>
        <section className="contact-deatils-address">
          <div className="container">
            <div className="row">
              <div className="col-md-5 rightbx bg-white"></div>
            </div>
          </div>
        </section>
      </div>

      <div className="modal welcome-modal fix-modal" role="dialog">
        <div className="modal-dialog for-modal-top" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-pb">
              <button
                type="button"
                onClick={closePopup}
                className="close cust-pop-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="model-btn-pop pointer">
                  &times;
                </span>
              </button>
              <div className="login d-flex align-items-center mt-20">
                <div className="container">
                  <div className="row">
                    <div className="col-md-11 col-lg-10">
                      <h5 className="get-touch">
                        Get free scholarship counselling
                      </h5>
                      <p className="mt-2">
                        Join our free IELTS demo class and pave your way to
                        studying at your dream destination abroad!
                      </p>
                    </div>

                    <div className="fields-wrp w-100">
                      <form id="queryFrom">
                        <div className="field">
                          <input
                            autoComplete="new-off"
                            type="text"
                            name="name"
                            required
                            value={Office?.name}
                            id="name"
                            onChange={(e) => {
                              hendlereviews(e);
                            }}
                          />
                          <label>name</label>
                          <span className="fa fa-user p-2"></span>
                        </div>
                        <div className="field">
                          <input
                            autoComplete="new-off"
                            type="text"
                            required
                            name="mobile"
                            maxLength={10}
                            id="mob"
                            value={Office?.mobile}
                            onChange={(e) => {
                              hendlereviews(e);
                            }}
                          />
                          <label>mobile</label>
                          <span className="fa fa-phone p-2"></span>
                        </div>
                        <div className="field">
                          <input
                            autoComplete="new-off"
                            type="text"
                            required
                            name="email"
                            id="email"
                            value={Office?.email}
                            onChange={(e) => {
                              hendlereviews(e);
                            }}
                          />
                          <label>email</label>
                          <span className="fa fa-envelope p-2"></span>
                        </div>
                        <div className="field">
                          <textarea
                            autoComplete="new-off"
                            className="q-msg"
                            rows={4}
                            cols={50}
                            placeholder="message"
                            name="message"
                            value={Office?.message}
                            onChange={(e) => {
                              hendlereviews(e);
                            }}
                          ></textarea>
                        </div>
                        <ReCAPTCHAComponent
                          onRecaptchaChange={handleRecaptchaChange}
                        />
                        <div
                          className="fieldbtn mt-50 mob-mb-25"
                          // style={{ marginBottom: "15px" }}
                        >
                          <div
                            onClick={(e) => {
                              submitQuery("queryFrom");
                            }}
                            className="bggreadent removeMinHeight btn btn-success br-5"
                          >
                            send
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WelComeForm;
